import React from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from 'theme';
import { CssBaseline } from '@material-ui/core';

import useStyles from './styles';

const NoAuthBrowserWrapper = ({ children }) => {
  const rootStyles = useStyles();

  return (
        <div className={rootStyles.root}>
         <ThemeProvider theme={theme}>
           <CssBaseline />
            {children}
        </ThemeProvider>
      </div>
  );
};

NoAuthBrowserWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default NoAuthBrowserWrapper;
