import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    width: '100%',
    minHeight: '100vh',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  rootNoFlex: {
    width: '100%',
    minHeight: '100vh',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: theme.palette.background.default,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.default.paper,
  },
});
